<template>
    <div class="logs-pages">
        <PageTitle icon="fa fa-bug" main="Logs"
            sub="Consulta e Análise de logs da aplicação." />
        
        <CadastroLogs />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroLogs from './CadastroLogs.vue'

export default {
    name: 'LogsPages',
    components: { PageTitle, CadastroLogs }
}
</script>

<style>

</style>
