<template>
  <div class="cadastro-logs">
    <div class="acoesTopo"> 
      <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'" @click="remove">
        <i class="fa fa-trash button-icons"></i> Excluir
      </b-button>
      <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" @click="novoModelo">
        <i class="fa fa-plus button-icons"></i> Novo
      </b-button>   
    </div>

    <!-- <b-card class="cadastros-card-pesquisa" v-if="show">
      <b-row align-v="center">
        <b-col md="6" sm="12" class="cadastros-pesquisa-coluna"> 
          <b-form-input id="localizar" type="search"
            :readonly="mode === 'remove'"
            placeholder="Informe parte da descrição do modelo..." />                     
        </b-col> 
        <b-col md="3" sm="12" class="d-flex justify-content-end">
          <b-button variant="info" @click="novaConsulta">
            <i class="fa fa-search button-icons"></i> Consultar
          </b-button>
        </b-col>
      </b-row>
    </b-card> -->

    <b-overlay :show="showOver" rounded="sm">
      <b-card class="cadastros-card-corpo" v-if="!show"></b-card>
    </b-overlay>   

    <b-card v-show="!show" style="margin-top: 10px">
      <div class="acoesRodape"> 
        <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'" @click="remove">
          <i class="fa fa-trash button-icons"></i> Excluir
        </b-button>           
        <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'" @click="novoModelo">
          <i class="fa fa-plus button-icons"></i> Novo
        </b-button>   
      </div>
    </b-card>   

    <b-card class="cadastros-card-corpo">  
        <b-button variant="info" @click="novaConsulta">
            <i class="fa fa-search button-icons"></i> Consultar
        </b-button>     
        <hr> 
        <b-tabs>            
            <b-tab v-for="(log, index) in logs" :key="index" :title="log.file">                

                <div>
                    <h4 class="mt-3">Filtros</h4>
                    <b-badge class="mr-2" style="font-size: 1.2rem" href="#" variant="danger" @click="filterLogs('error', log)">Error</b-badge>
                    <b-badge class="mr-2" style="font-size: 1.2rem" href="#" variant="warning" @click="filterLogs('warn', log)">Warn</b-badge>
                    <b-badge class="mr-2" style="font-size: 1.2rem" href="#" variant="info" @click="filterLogs('info', log)">Info</b-badge>
                    <b-badge class="mr-2" style="font-size: 1.2rem" href="#" variant="dark" @click="filterLogs('debug', log)">Debug</b-badge>
                    <b-badge class="mr-2" style="font-size: 1.2rem" href="#" variant="secondary" @click="filterLogs(null, log)">Todos</b-badge>
                    <b-badge class="ml-3" style="font-size: 1.2rem" href="#" variant="ligth" @click="toggleExibeJson"><i class="fa fa-code mr-1"></i>{{ exibeJson ? 'Exibir Tabela' : 'Exibir JSON' }}</b-badge>
                    <b-badge class="ml-3" style="font-size: 1.2rem" href="#" variant="ligth" @click="remove(log.file)"><i class="fa fa-trash mr-1"></i> Apagar Log</b-badge>
                </div>

                <hr>

                <b-form-textarea
                    v-if="exibeJson"
                    v-model="logsRaw"
                    id="textarea-rows-logs-raw"
                    placeholder="Nada por aqui..."
                    rows="15"
                ></b-form-textarea>

                <b-table v-if="!exibeJson" :items="log.filteredLogs" :fields="fields" hover striped responsive sort-icon-left>

                    <template #cell(show_details)="row">
                        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                            {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} Detalhes
                        </b-button>
                    </template>                    

                    <template #row-details="row">
                        <b-card>
                            <b-row class="mb-2">
                                <b-col class="text-sm-right">
                                    <b-form-textarea
                                        v-model="row.item.content"
                                        id="textarea-rows"
                                        placeholder="Nada por aqui..."
                                        rows="5"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>

                    <template v-slot:cell(level)="data">
                        <b-badge style="font-size: 1rem" v-if="data.item.level == 'info'" variant="info">{{data.item.level}}</b-badge>
                        <b-badge style="font-size: 1rem" v-else-if="data.item.level == 'warn'" variant="warning">{{data.item.level}}</b-badge>
                        <b-badge style="font-size: 1rem" v-else-if="data.item.level == 'error'" variant="danger">{{data.item.level}}</b-badge>
                        <b-badge style="font-size: 1rem" v-else-if="data.item.level == 'debug'" variant="dark">{{data.item.level}}</b-badge>
                        <b-badge style="font-size: 1rem" v-else variant="secondary">{{data.item.level}}</b-badge>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
    </b-card>  
  </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
const moment = require('moment')

export default {
    name: 'CadastroLogs',
    data: function() {
        return {
            selectedLevel: null,  // Nenhum nível selecionado por padrão
            exibeJson: false,
            fields: [
                {key: 'level', label: 'Tipo', sortable: true},
                {key: 'timestamp', label: 'Data/Hora', sortable: true, formatter: value => { 
                        return value ? moment(value).format('DD/MM/yyyy HH:mm:ss') : null
                    }
                },                
                {key: 'message', label: 'Componente/Método', sortable: true},
                {key: 'error', label: 'Erro', sortable: true},
                {key: 'show_details', label: '', sortable: false},
            ],
            logs: [], // Armazenando os logs
            logsRaw: [],
            mode: 'new',            
            page: 1,
            count: 0,
            limit: 0,
            show: true,
            showOver: false,
        }
    },
    methods: {
        // Método para filtrar os logs por nível e atualizar log.filteredLogs
        filterLogs(level = null, log) {
            // Filtra os logs dentro do arquivo de log atual (log)
            if (level) {
                log.filteredLogs = log.parsedContent.filter(entry => entry.level === level);
            } else {
                // Se nenhum nível for selecionado, mostra todos os logs
                log.filteredLogs = log.parsedContent;
            }
        },

        toggleExibeJson() {
            this.exibeJson = !this.exibeJson
        },

        novoModelo() {
            this.reset()
            this.mode = 'save'
        },

        novaConsulta() {
            this.page = 1 
            this.loadLogs()
        },

        loadLogs() {
            axios.get(`${baseApiUrl}/logs`)
            .then(res => {
                this.logsRaw = res.data; // Aqui estamos assumindo que os dados chegam como uma lista de logs.

                this.logs = res.data.map(log => {
                    // Para cada log, vamos garantir que a propriedade `content` seja um objeto válido
                    let parsedContent = [];

                    if (log.content) {
                        // Garantindo que o conteúdo do log seja um objeto, já que `content` pode ser um objeto complexo
                        parsedContent = [{
                            content: log.content,
                            level: log.level,
                            message: log.message,
                            timestamp: log.timestamp
                        }];
                    }

                    return {
                        file: log.file, // Presumindo que `file` é uma propriedade do log
                        parsedContent,
                        filteredLogs: parsedContent // Inicializa com o conteúdo parseado
                    };
                });

                this.count = res.data.count;
                this.limit = res.data.limit;
            })
            .catch(showError);
        },

        loadLogs2() { 
            axios.get(`${baseApiUrl}/logs`)
            .then(res => {
                this.logsRaw = res.data
                this.logs = []
                this.logs = res.data.map(log => {
                    let parsedContent = log.content.split("\r\n").map(entry => {
                        try {
                            return JSON.parse(entry); // Parse cada linha JSON
                        } catch (e) {
                            return null;
                        }
                    }).filter(entry => entry !== null); // Filtra registros inválidos

                    // Inicializando filteredLogs com todo o conteúdo do parsedContent
                    return {
                        file: log.file,
                        parsedContent, // Conteúdo parseado
                        filteredLogs: parsedContent // Inicializa a lista filtrada com todos os logs
                    };
                });

                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(showError)           
        },

        remove(logFile) {
            axios.delete(`${baseApiUrl}/logs/?file=${logFile}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.loadLogs()
            })
            .catch(showError)
        },
    },

    watch: {
        page() {
            this.loadLogs()
        }
    }
}
</script>


<style scoped>
    /* Estilos adaptados para garantir boa visualização das abas e conteúdo */
    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .scroll {
        overflow-y: scroll;
    }

    pre {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
</style>
