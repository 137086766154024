<template>
    <div class="cadastro-pessoas">
        
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novaPessoa">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        :readonly="mode === 'remove'"
                        placeholder="Informe parte do nome do cliente..." />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-show="!show">
                <b-form>
                    <input id="pessoa-id" type="hidden" v-model="pessoa.idpessoa" />

                    <b-row>                       
                        <b-col md="6" sm="12">
                            <b-form-group label="Nome / Razão Social" label-for="pessoa-nome"> 
                                <b-form-input id="pessoa-nome" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.razao_social" required
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe o Nome/Razão Social da pessoa..." />
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Apelido / Fantasia" label-for="pessoa-fantasia"> 
                                <b-form-input id="pessoa-fantasia" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.fantasia" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="2" sm="12">
                            <b-form-group label="Tipo" label-for="tipo-pessoa">
                                <b-form-select id="tipo-pessoa" v-model="pessoa.tipo_fj" :options="optionsTipo" />
                            </b-form-group>
                        </b-col> 
                          
                    </b-row>

                    <b-row>     
                        <b-col md="3" sm="12" v-show="pessoa.tipo_fj === 'F'">
                            <b-form-group label="CPF" label-for="pessoa-cpf-cad"> 
                                <b-form-input id="pessoa-cpf-cad" type="text"  
                                    v-mask="mascaras.cpf"
                                    v-model="pessoa.cpf"
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col> 

                        <b-col md="3" sm="12" v-show="pessoa.tipo_fj === 'J'">
                            <b-form-group label="CNPJ" label-for="pessoa-cnpj-cad"> 
                                <b-input-group>
                                    <b-form-input id="pessoa-cnpj-cad" type="text"
                                        v-mask="mascaras.cnpj"
                                        v-model="pessoa.cnpj"
                                        :readonly="mode === 'remove'" />
                                    <b-input-group-append>
                                        <b-button variant="success" @click="buscarCNPJ(pessoa.cnpj)"><i class="fa fa-refresh"></i></b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>  

                        <b-col md="3" sm="12" v-show="pessoa.tipo_fj === 'F'">
                            <b-form-group label="RG" label-for="pessoa-rg"> 
                                <b-form-input id="pessoa-rg" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.rg"
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12" v-show="pessoa.tipo_fj === 'J'">
                            <b-form-group label="IE" label-for="pessoa-ie"> 
                                <b-form-input id="pessoa-ie" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.ie"
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col>    
                        <b-col md="3" sm="12">
                            <b-form-group label="IM" label-for="pessoa-im"> 
                                <b-form-input id="pessoa-im" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.im"
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col>    
                        <b-col md="3" sm="12" v-show="pessoa.tipo_fj === 'J'">
                            <b-form-group label="Suframa" label-for="pessoa-suframa"> 
                                <b-form-input id="pessoa-suframa" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.suframa"
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col>    
                    </b-row>

                    <b-row>
                        <b-col md="2" sm="12">
                            <b-form-group label="CEP" label-for="pessoa-cep"> 
                                <b-input-group  id="pessoa-cep" class="mt-2">
                                    <b-form-input  type="text"
                                        v-mask="mascaras.cep"
                                        :formatter="upperCase"
                                        v-model="pessoa.cep"
                                        :readonly="mode === 'remove'" />
                                        <b-input-group-append>
                                            <b-button :disabled="mode === 'remove'" variant="success" @click="loadCep(pessoa.cep)"><i class="fa fa-refresh atualizar"></i></b-button>
                                        </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col> 
                        <b-col md="5" sm="12">
                            <b-form-group label="Endereço" label-for="pessoa-endereco"> 
                                <b-form-input id="pessoa-endereco" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.endereco" required
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe o endereço da pessoa..." />
                            </b-form-group>
                        </b-col> 
                        <b-col md="1" sm="12">
                            <b-form-group label="Nº." label-for="pessoa-numero"> 
                                <b-form-input id="pessoa-numemo" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.numero"
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Bairro" label-for="pessoa-bairro"> 
                                <b-form-input id="pessoa-bairro" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.bairro"
                                    :readonly="mode === 'remove'" 
                                    placeholder="Informe o bairro..." />
                            </b-form-group>
                        </b-col> 
                    </b-row>
                    
                    <b-row>                         
                        <b-col md="7" sm="12">
                            <b-form-group label="Complemento" label-for="pessoa-complemento"> 
                                <b-form-input id="pessoa-complemento" type="text"
                                    :formatter="upperCase"
                                    v-model="pessoa.complemento" required
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe o complemento de endereço..." />
                            </b-form-group>
                        </b-col>
                        <b-col md="5" sm="12">
                            <b-form-group label="Cidade" label-for="pessoa-cidade-cad">
                                    <Autocomplete
                                        id="pessoa-cidade-cad"
                                        :options="cidades"
                                        label-key="nome"
                                        value-key="id_cidade"
                                        v-model="pessoa.idcidade"
                                        placeholder=""
                                        @shouldSearch="loadCidades"
                                        @select="onSelect"
                                    />
                            </b-form-group>                        
                        </b-col>
                        
                    </b-row>
                    <b-row>
                        <b-col md="3" sm="12">
                            <b-form-group label="Celular" label-for="pessoa-celular"> 
                                <b-input-group>
                                    <div style="width: 55px;">
                                        <b-form-input id="pessoa-celular-ddd" type="text" 
                                        v-mask="mascaras.ddd"
                                        :formatter="upperCase"
                                        placeholder="DDD"
                                        v-model="pessoa.ddd_celular" required
                                        :readonly="mode === 'remove'" />
                                    </div>

                                    <b-form-input id="pessoa-celular" type="text"
                                    v-mask="mascaras.celular"
                                    :formatter="upperCase"
                                    v-model="pessoa.celular" required
                                    :readonly="mode === 'remove'" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>  
                        <b-col md="3" sm="12">
                            <b-form-group label="Telefone" label-for="pessoa-telefone"> 
                                <b-input-group>
                                    <div style="width: 55px;">
                                        <b-form-input id="pessoa-telefone-ddd" type="text" 
                                        v-mask="mascaras.ddd"
                                        :formatter="upperCase"
                                        placeholder="DDD"
                                        v-model="pessoa.ddd_telefone" required
                                        :readonly="mode === 'remove'" />
                                    </div>

                                    <b-form-input id="pessoa-telefone" type="text"
                                    v-mask="mascaras.celular"
                                    :formatter="upperCase"
                                    v-model="pessoa.telefone" required
                                    :readonly="mode === 'remove'" />
                                </b-input-group>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="E-mail" label-for="pessoa-email"> 
                                <b-form-input id="pessoa-email" type="text"
                                    :formatter="lowerCase"
                                    v-model="pessoa.email" required
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group>
                                <label for="pessoa-email-cobranca">
                                    E-mail de Cobrança
                                    <i class="fa fa-question-circle" 
                                        v-b-tooltip.hover 
                                        title="Se o campo for preenchido, as faturas desta pessoa vão entrar no sistema automático de cobrança."
                                        style="cursor: pointer; color: #004b87; margin-left: 5px;">
                                    </i>
                                </label>

                                <b-form-input id="pessoa-email-cobranca" type="text"
                                    :formatter="lowerCase"
                                    v-model="pessoa.email_cobranca" required
                                    :readonly="mode === 'remove'" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <br>

                    <b-card class="cadastros-opcoes-row-card">
                        <b-row class="cadastros-opcoes-row" align-h="around">
                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="pessoa-ativo" checked v-show="mode === 'save'" v-model="pessoa.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="pessoa-e-cliente" checked v-show="mode === 'save'" v-model="pessoa.e_cliente" class="mt-3 mb-3" switch>Cliente</b-form-checkbox>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="pessoa-e-afiliado" checked v-show="mode === 'save'" v-model="pessoa.e_afiliado" class="mt-3 mb-3" switch>Afiliado</b-form-checkbox>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="pessoa-e-fornecedor" checked v-show="mode === 'save'" v-model="pessoa.e_fornecedor" class="mt-3 mb-3" switch>Fornecedor</b-form-checkbox>
                            </b-col>
                            <b-col md="2" sm="12" v-if="pessoa.tipo_fj === 'F'">
                                <b-form-checkbox id="pessoa-e-funcionario" checked v-show="mode === 'save'" v-model="pessoa.e_funcionario" class="mt-3 mb-3" switch>Funcionário</b-form-checkbox>
                            </b-col>
                            <b-col md="2" sm="12" v-else>
                                <b-form-checkbox id="pessoa-e-orgao" checked v-show="mode === 'save'" v-model="pessoa.e_orgao_publico" class="mt-3 mb-3" switch>Órgão Público</b-form-checkbox>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="pessoa-e-vendedor" checked v-show="mode === 'save'" v-model="pessoa.e_vendedor" class="mt-3 mb-3" switch>Vendedor</b-form-checkbox>
                            </b-col>
                            
                        </b-row>
                    </b-card>
                    
                    <br>
                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{pessoa.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{pessoa.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{pessoa.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{pessoa.data_alt}}
                            </b-popover>                        
                        </b-col>

                    </b-row>

                </b-form>
            </b-card>
        </b-overlay>   

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novaPessoa">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="pessoas" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadPessoa(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="danger" @click="loadPessoa(data.item, 'remove')">
                        <i class="fa fa-trash"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>  
    </div>
</template>

<script>

import { baseApiUrl, showError, jquery} from '@/global'
import Autocomplete from '@/components/autocomplete/Autocomplete.vue'
const moment = require('moment')
import axios from 'axios'

function getReceita(cnpj, callback){
    jquery.ajax({
        url: "https://www.receitaws.com.br/v1/cnpj/"+ cnpj,
        method:'GET',
        dataType: 'jsonp',
    }).done(function(data) {
        callback(data);
    });
}

export default {
    name: 'CadastroPessoas',
    components: { Autocomplete },
    data: function() {
        return {
            teste: {},
            mode: 'new',
            pessoa: {
                idcidade: '',
                venc_padrao: moment('1900-01-01').format('YYYY-MM-DD'),
                ativo: true,
                e_cliente: true,
                e_afiliado: false,
                e_fornecedor: false,
                e_funcionario: false,                
                e_vendedor: false,                
                tipo_fj: 'F',
            },
            pessoas: [],
            page: 1,
            count: 0,
            limit: 0,
            unidades: [],
            cidades: [],
            parcial: '',
            ativos: 'S',
            show: true,
            showOver: false,
            mascaras: {
                masked: true,
                cpf: '###.###.###-##',
                cnpj: '##.###.###/####-##',
                telefone: '####-####',
                ddd: '(##)',
                celular: '# ####-####',
                cep: '#####-###'
            },
            fields: [
                {key: 'idpessoa', label: 'Código', sortable: true},
                {key: 'razao_social', label: 'Nome / Razão Social', sortable: true},
                {key: 'tipo_fj', label: 'Tipo', sortable: true, formatter: value => value ==='F' ? 'Física' : 'Jurídica'},
                {key: 'celular_completo', label: 'Celular', sortable: false},
                {key: 'telefone_completo', label: 'Telefone', sortable: false},                
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],
            optionsTipo: [
                { value: 'F', text: 'Pessoa Física' },
                { value: 'J', text: 'Pessoa Jurídica'}
            ],
            receitaCNPJ: {}         
        }
    },
    methods: {
        soNumeros(value) { 
            const n = value ? value.replace(/[^\d]+/g,'') : ''
            return n.length
        },
        novaPessoa() {
            this.reset()
            this.mode = 'save'
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadPessoas()
        },
        loadCep(cep) {
            let str = cep.replace("-", "").replace(".", "");

            if (str.length === 8) {
                axios.get(`https://viacep.com.br/ws/${str}/json/`)
                    .then(res => {
                        this.pessoa.endereco = res.data.logradouro
                        this.pessoa.bairro = res.data.bairro
                        this.pessoa.complemento = res.data.complemento
                        
                        //Localizar a cidade
                        const url = `${baseApiUrl}/cidades/estado/${res.data.uf}/?nome=${res.data.localidade}`    

                        axios.get(url).then(resp => {
                            this.pessoa.id_cidade = resp.data.id_cidade
                            this.pessoa.idcidade = resp.data.nome
                            this.teste = resp.data
                        }).catch(err => {
                            showError(err)
                        })
                    })
                    .catch(error => {
                        showError(error)
                    })
            }
        },
        loadPessoas() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&ativos=${this.ativos}&parcial=${this.parcial}&tipo=cadpessoa` 

            axios.get(url).then(res => {
                this.pessoas = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(showError)           
        },
        loadCidades() {  
            const url = `${baseApiUrl}/cidades`

            axios.get(url).then(res => {
                this.cidades = res.data
            })
        },
        onSelect(cidade) {
            this.pessoa.id_cidade = cidade.id_cidade
        },
        consultarCadastro() {
            const parc = (this.parcial.trim().length > 0) ? this.parcial : 'xxxxxxxxxx' 
            const url = `${baseApiUrl}/pessoas/consulta/${parc}/ativos/${this.ativos}`           

            axios.get(url).then(res => {
                this.pessoas = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit  
            })
        },
        reset() {
            this.mode = 'new'
            
            this.pessoa = {
                id_cidade: '',
                idcidade: '',
                tipo_fj: 'F',
                cpf: '',
                cnpj: '',
            }
            this.pessoa.venc_padrao = moment('1900-01-01').format('YYYY-MM-DD')
            this.pessoa.ativo = true
            this.pessoa.e_cliente = true

            this.loadPessoas()
            this.show = !this.show
        },
        save() {
            const method = this.pessoa.idpessoa ? 'put' : 'post'
            const idpessoa = this.pessoa.idpessoa ? `/${this.pessoa.idpessoa}` : ''
            this.pessoa.ativo == true ? this.pessoa.ativo='S' : this.pessoa.ativo='N'   
            this.pessoa.e_cliente == true ? this.pessoa.e_cliente='S' : this.pessoa.e_cliente='N' 
            this.pessoa.e_afiliado == true ? this.pessoa.e_afiliado='S' : this.pessoa.e_afiliado='N' 
            this.pessoa.e_fornecedor == true ? this.pessoa.e_fornecedor='S' : this.pessoa.e_fornecedor='N' 
            this.pessoa.e_funcionario == true ? this.pessoa.e_funcionario='S' : this.pessoa.e_funcionario='N' 
            this.pessoa.e_vendedor == true ? this.pessoa.e_vendedor='S' : this.pessoa.e_vendedor='N' 
            
            this.pessoa.idcidade = this.pessoa.id_cidade            

            axios[method](`${baseApiUrl}/pessoas${idpessoa}`, this.pessoa)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)

            this.pessoa.ativo == 'S' ? this.pessoa.ativo = true : this.pessoa.ativo = false
            this.pessoa.e_cliente == 'S' ? this.pessoa.e_cliente = true : this.pessoa.e_cliente = false
            this.pessoa.e_afiliado == 'S' ? this.pessoa.e_afiliado = true : this.pessoa.e_afiliado = false
            this.pessoa.e_fornecedor == 'S' ? this.pessoa.e_fornecedor = true : this.pessoa.e_fornecedor = false
            this.pessoa.e_funcionario == 'S' ? this.pessoa.e_funcionario = true : this.pessoa.e_funcionario = false
            this.pessoa.e_vendedor == 'S' ? this.pessoa.e_vendedor = true : this.pessoa.e_vendedor = false
            
        },
        remove() {
            const idpessoa = this.pessoa.idpessoa
            axios.delete(`${baseApiUrl}/pessoas/${idpessoa}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(showError)
        },

        buscarCNPJ(cnpjConsulta) {  
            const c = cnpjConsulta.replace(/[^\d]+/g,'') //só numeros

            getReceita(c, p=>{
                this.receitaCNPJ = p 
                this.pessoa.razao_social = this.receitaCNPJ.nome
                this.pessoa.endereco = this.receitaCNPJ.logradouro
                this.pessoa.numero = this.receitaCNPJ.numero
                this.pessoa.complemento = this.receitaCNPJ.complemento
                this.pessoa.cep = this.receitaCNPJ.cep
                this.pessoa.email = this.receitaCNPJ.email
                this.pessoa.fantasia = this.receitaCNPJ.fantasia

                if (this.receitaCNPJ.telefone && this.receitaCNPJ.telefone.length > 9) {
                    const telCompleto = this.receitaCNPJ.telefone.replace(/[^\d]+/g,'')
                    const ddd = telCompleto.substring(0,2)
                    const tel = telCompleto.substring(2,18)
                    this.pessoa.ddd_telefone = ddd
                    this.pessoa.telefone = tel
                }

                //Localizar a cidade
                axios.get(`${baseApiUrl}/cidades/estado/${this.receitaCNPJ.uf}/?nome=${this.receitaCNPJ.municipio}`)
                .then(resp => {
                    this.pessoa.id_cidade = resp.data.id_cidade
                    this.pessoa.idcidade = resp.data.nome
                }).catch(err => {
                    showError(err)
                })
            })            
        },

        loadPessoa(pessoa, mode = 'save') {
            this.show = !this.show 
            this.showOver = !this.showOver 

            const url = `${baseApiUrl}/pessoas/${pessoa.idpessoa}`    

            axios.get(url).then(res => {
                
                this.showOver = !this.showOver 
                
                this.mode = mode
                this.pessoa = { ...res.data }
                this.pessoa.venc_padrao = moment(this.pessoa.venc_padrao).format('YYYY-MM-DD')  
                this.pessoa.data_cad = moment(this.pessoa.data_cad).format('DD/MM/YYYY HH:mm:ss')
                this.pessoa.data_alt = moment(this.pessoa.data_alt).format('DD/MM/YYYY HH:mm:ss')
                this.pessoa.ativo ==='S' ? this.pessoa.ativo=true : this.pessoa.ativo= false
                this.pessoa.e_cliente  ==='S' ? this.pessoa.e_cliente=true : this.pessoa.e_cliente= false
                this.pessoa.e_afiliado ==='S' ? this.pessoa.e_afiliado=true : this.pessoa.e_afiliado= false
                this.pessoa.e_fornecedor ==='S' ? this.pessoa.e_fornecedor=true : this.pessoa.e_fornecedor= false
                this.pessoa.e_funcionario ==='S' ? this.pessoa.e_funcionario=true : this.pessoa.e_funcionario= false 
                this.pessoa.e_vendedor ==='S' ? this.pessoa.e_vendedor=true : this.pessoa.e_vendedor= false 

                this.pessoa.id_cidade = this.pessoa.cidade_id
                this.pessoa.idcidade = this.pessoa.nome_cidade

                


                const nome = this.pessoa.razao_social
                this.pessoa.razao_social = ''
                this.pessoa.razao_social = nome
                // setTimeout(()=>{
                //     this.pessoa.razao_social = nome
                // },1)

            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })
        }
    
    },
    watch: {
        page() {
            this.loadPessoas()
        }
    },
    mounted() {
        this.loadPessoas()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    



</style>