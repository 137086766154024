<template>
    <div class="cadastro-lembretes">
        
        <div class="acoesTopo">             
            <b-button variant="secondary" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" @click="reset">
                <i class="fa fa-times button-icons"></i> Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'" @click="remove">
                <i class="fa fa-trash button-icons"></i> Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" @click="save">
                <i class="fa fa-check button-icons"></i> Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" @click="novoLembrete">
                <i class="fa fa-plus button-icons"></i> Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna">
                    <b-form-input id="localizar" type="search" :formatter="upperCase" v-model="parcial"
                        :readonly="mode === 'remove'" placeholder="Informe parte do nome do setor..." />                     
                </b-col> 
                <b-col md="3" sm="12">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots">
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-show="!show">
                <b-form>
                    <input id="lembrete-id" type="hidden" v-model="lembrete.id_lembrete_configuracao" />
                    
                    <b-row>                       
                        <b-col md="5" sm="12">
                            <b-form-group label="Nome do Setor" label-for="lembrete-nome-setor"> 
                                <b-form-input id="lembrete-nome-setor" type="text"
                                    :formatter="upperCase"
                                    v-model="lembrete.nome_setor" required
                                    :readonly="mode === 'remove'"
                                    placeholder="Informe o nome do setor..." />
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" sm="6">
                                <b-form-group label="Filial" label-for="filial-name-inputt">
                                    <vue-single-select
                                        id="filial-name-input"                            
                                        placeholder="Procurar"
                                        v-model="filialSelecionada"
                                        :options="filiais"
                                        option-label="text"
                                        option-key="value"
                                        :get-option-description="label"
                                        :get-option-value="value"
                                    ></vue-single-select>
                                </b-form-group> 
                            </b-col>
                        <b-col md="2" sm="12">
                            <br>
                            <b-form-checkbox value="S" unchecked-value="N" id="lembrete-ativo" checked v-show="mode === 'save'" v-model="lembrete.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                        </b-col> 
                    </b-row>
                    <br>

                    <b-tabs small content-class="mt-3">                        
                        <b-tab title="Configuração da Conta de E-mail" active>
                            <b-row>  
                                <b-col md="4" sm="12">
                                    <b-form-group label="Usuário" label-for="config-email-user"> 
                                        <b-form-input size="sm" id="config-email-user" type="email" v-model="lembrete.email_user" />
                                    </b-form-group>

                                    <b-form-group label="Senha" label-for="config-email-pass"> 
                                        <b-form-input size="sm" id="config-email-pass" type="password" v-model="lembrete.email_pass" />
                                    </b-form-group>

                                    <b-form-group label="Responder para" label-for="config-email-reply"> 
                                        <b-form-input size="sm" id="config-email-reply" type="email" v-model="lembrete.email_user_reply" />
                                    </b-form-group>
                                </b-col>

                                <b-col md="4" sm="12">
                                    <b-form-group label="Servidor" label-for="config-email-host"> 
                                        <b-form-input size="sm" id="config-email-host" type="text" v-model="lembrete.email_host" />
                                    </b-form-group>
                                    
                                    <b-form-group label="Porta" label-for="config-email-port"> 
                                        <b-form-input size="sm" id="config-email-port" type="number" v-model="lembrete.email_port" />
                                    </b-form-group>
                                </b-col>

                                <b-col md="4" sm="12">                                    
                                    <b-form-group label="Protocolo" label-for="radio-slots-protocol"> 
                                        <b-form-radio-group 
                                            id="radio-slots-protocol"
                                            v-model="lembrete.email_protocol"
                                            name="config-email-user"
                                        >
                                            <b-form-radio value="SSL">SSL</b-form-radio>
                                            <b-form-radio value="TLS">TLS</b-form-radio>
                                            <b-form-radio value="AMBOS">Ambos</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>
                    <br>

                    <b-card header="Lembretes do Setor" header-bg-variant="light">  
                        <b-row> 
                            <div>
                                <b-button size="sm" variant="primary" @click="novoModelo()">
                                    <i class="fa fa-plus"></i> Novo Lembrete
                                </b-button>                            
                            </div>
                        </b-row>

                        <b-modal
                            hideBvModalEvent
                            v-model="modalLembrete"
                            id="modal-tipos"
                            centered size="xl"
                            ok-only
                            ok-disabled
                            title="Adicionar/Editar Lembretes"
                        >
                            <b-row>
                                <b-col md="3" sm="12">
                                    <b-form-group label-for="lembrete-modelo-descricao">
                                    Descrição
                                    <b-form-input id="lembrete-modelo-descricao" type="text" v-model="modelo.descricao" required />
                                    </b-form-group>

                                    <b-form-group label-for="lembrete-modelo-quando">
                                    Quando
                                    <b-form-select id="lembrete-modelo-quando" v-model="modelo.e_quando" :options="[{value: 'A', text: 'Antes'},{value: 'D', text: 'Depois'}]" />
                                    </b-form-group>

                                    <b-form-group label-for="lembrete-modelo-qdias">
                                    Dias
                                    <b-form-input id="lembrete-modelo-qdias" type="number" v-model="modelo.qtde_dias" required />
                                    </b-form-group>

                                    <p><strong>{{ descricaoLembrete }}</strong></p>
                                </b-col>

                                <b-col md="9" sm="12">
                                    <b-form-group label="Modelos de E-mail" label-for="modelos-email">
                                        <vue-single-select
                                            id="modelos-email"                            
                                            placeholder="Selecione"
                                            v-model="modeloSelecionado"
                                            :options="modelosProntos"
                                            option-label="text"
                                            option-key="value"
                                            :get-option-description="label"
                                            :get-option-value="value"
                                        ></vue-single-select>
                                    </b-form-group>

                                    <b-form-group label-for="lembrete-modelo-assunto">
                                        Assunto do E-mail
                                        <b-form-input id="lembrete-modelo-assunto" type="text" v-model="modelo.e_mail_assunto" required />
                                    </b-form-group>
                                    
                                    <!-- VueEditor com Menu de Contexto -->
                                    <div 
                                        @contextmenu.prevent="showContextMenu" 
                                        ref="editor"
                                        class="editor-container"
                                    >                                    
                                        <VueEditor
                                            @selection-change="onSelectionChange"
                                            v-model="modelo.e_mail_corpo"
                                            :editor-toolbar="toolbarOptions"
                                            @ready="onEditorReady"
                                        />
                                    </div>
                                    <p><strong>Clique com o botão direito no local onde deseja inserir um novo campo</strong>.</p>

                                    <!-- Menu de Contexto Customizado -->
                                    <div 
                                        v-if="showMenu" 
                                        :style="menuStyles" 
                                        class="custom-context-menu"
                                    >
                                        <div class="custom-context-menu-t">
                                            <h6><strong>Campos</strong></h6>
                                        </div>
                                        <hr>
                                        <ul>
                                            <li v-for="item in contextMenuOptions" :key="item.value" @click="insertText(item.value)">
                                            {{ item.text }}
                                            </li>
                                        </ul>
                                    </div>
                                </b-col>
                            </b-row>

                            <template #modal-footer>
                            <div class="w-100">
                                <b-col md="10" sm="10">
                                <b-button variant="primary" @click="saveModelo()">Salvar</b-button>
                                <b-button class="ml-2" @click="resetModelo()">Cancelar</b-button>
                                </b-col>
                            </div>
                            </template>
                        </b-modal>
                        

                        <b-row  v-if="lembrete.modelos">
                            <b-table class="mt-2" hover striped :items="lembrete.modelos" :fields="fieldsModelos" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button size="sm" variant="warning" @click="loadModelo(data.item)" class="mr-2">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button size="sm" variant="danger" @click="desejaRemoverModelo(data.item)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                                

                                <template v-slot:cell(ativo)="data">
                                    <b-form-checkbox v-model="data.item.ativo" @change="ativarDesativar(data)" unchecked-value="N" value="S" switch />
                                </template>
                            </b-table>
                        </b-row>  

                        <b-modal id="modal-remove-modelo" title="Remover lembrete" v-model="modalRemModelo"   >
                            <p class="my-4">Deseja realmente remover este lembrete?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeModelo()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-modelo')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>

                        </b-modal>                
                    </b-card>

                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                         
                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>
                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{lembrete.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{lembrete.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{lembrete.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{lembrete.data_alt}}
                            </b-popover>                        
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-overlay>   

        <b-card v-show="!show" style="margin-top: 10px">
            <div class="acoesRodape">                 
                <b-button variant="secondary" class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" @click="reset">
                    <i class="fa fa-times button-icons"></i> Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'" @click="remove">
                    <i class="fa fa-trash button-icons"></i> Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" @click="save">
                    <i class="fa fa-check button-icons"></i> Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'" @click="novoLembrete">
                    <i class="fa fa-plus button-icons"></i> Novo
                </b-button>   
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table 
                hover 
                striped 
                :items="lembretes" 
                :fields="fields" 
                sort-icon-left 
                small 
                borderless                                
                responsive
            >  
                <template v-slot:cell(actions)="data">
                    <div  class="d-flex justify-content-end">
                        <b-button size="sm" variant="primary" @click="loadLembrete(data.item)" class="mb-1 mr-1" v-b-tooltip.hover.bottomleft="'Editar'">
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <b-button size="sm" variant="danger" @click="desejaRemoverLembrete(data.item)" class="mb-1" v-b-tooltip.hover.bottomleft="'Excluir'">
                            <i class="fa fa-trash m-1"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>
            <hr>

            <b-pagination align="center" size="md" v-model="page" :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card> 

        <b-modal id="modal-remove-config" title="Deletar Configuração de Lembrete" v-model="modalRemLembrete">
            <p class="my-4">Deseja realmente excluir esta configuração de lembrete?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-remove-conta')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal> 
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import VueSingleSelect from "vue-single-select";
import { VueEditor } from "vue2-editor"
import axios from 'axios'

export default {
    name: 'CadastroLembretes',
    components: { VueEditor, VueSingleSelect },
    data: function() {
        return {
            testes: {},
            modalRemLembrete: false,

            filiais: [],
            filialSelecionada: '',

            // VueEditor
            quill: null,
            cursor: {},
            toolbarOptions: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                // [{ background: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'align': [] }],
                ['link', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
            ],
            showMenu: false,
            menuPosition: { top: 0, left: 0 },
            contextMenuOptions: [
                { text: 'Nome/Razão do Cliente', value: '@nome-cliente@' },
                { text: 'Forma de Pagamento', value: '@forma-pagamento@' },
                { text: 'Valor Total', value: '@valor-total@' },
                { text: 'Valor Total (Extenso)', value: '@valor-total-extenso@' },
                { text: 'Vencimento', value: '@vencimento@' },
                { text: 'Vencimento (Extenso)', value: '@vencimento-extenso@' },
                { text: 'Data do Envio', value: '@data-hoje@' },
                { text: 'Data do Envio (Extenso)', value: '@data-hoje-extenso@' },                

                { text: 'Razão Social (Filial)', value: '@razao-social-filial@' },
                { text: 'Fantasia (Filial)', value: '@fantasia-filial@' },
                { text: 'Telefone (Filial)', value: '@telefone-filial@' },
                { text: 'Celular (Filial)', value: '@celular-filial@' },
                { text: 'Endereço (Filial)', value: '@endereco-filial@' },
                { text: 'E-mail (Filial)', value: '@email-filial@' },
            ],
            // ******************************* */            

            mode: 'new',
            lembrete: {
                id_lembrete_configuracao: '',
                nome_setor: '',
                email_ativar_envio: '',
                email_user: '',
                email_pass: '',
                email_user_reply: '',
                email_host: '',
                email_port: '',
                email_protocol: 'AMBOS', // Valor default
                ativo: true,
                modelos: []
            },
            modelo: {
                descricao: '',
                e_quando: 'A',
                qtde_dias: 5,
                e_mail_assunto: '',
                e_mail_corpo: '',
                ativo: 'S'
            },
            modalLembrete: false,
            modalRemModelo: false,
            lembretes: [],
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            showOver: false,
            protocolos: [
                { value: 'SSL', text: 'SSL' },
                { value: 'TLS', text: 'TLS' },
                { value: 'AMBOS', text: 'AMBOS' }
            ],
            fields: [
                {key: 'id_lembrete_configuracao', label: 'Código', sortable: true},
                {key: 'nome_setor', label: 'Nome Setor', sortable: true},
                {key: 'email_ativar_envio', label: 'E-mail Ativação', sortable: true},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value === 'S' ? 'Sim' : 'Não'},
                {key: 'actions', label: ''}
            ],
            fieldsModelos: [
                {key: 'descricao', label: 'Nome Setor', sortable: true},
                {key: 'e_quando', label: 'Quando', sortable: false, formatter: value => value === 'A' ? 'Antes' : 'Depois'},
                {key: 'qtde_dias', label: 'Qtde Dias', sortable: true},
                {key: 'actions', label: ''}
            ],

            modeloSelecionado: '',
            modelosProntos: [
                { text: 'Carta de Cobrança', value: '', assunto: 'Lembrete de Vencimento', modelo: 
`<h1 style="color: #004b87;">Lembrete de Vencimento</h1><p>
</p><p>Prezado(a) <strong>@nome-cliente@</strong>,</p><p>Gostaríamos de lembrá-lo de que o pagamento referente à sua fatura, com vencimento em <strong>@vencimento@</strong>, ainda não foi realizado. O valor total da fatura é de <strong>@valor-total@</strong>.</p><p>
</p><p>Por favor, verifique as informações da sua fatura abaixo:   
</p><p><strong>Forma de pagamento:</strong> @forma-pagamento@</p><p><strong>Data de vencimento:</strong> @vencimento@</p><p><strong>Valor total:</strong> @valor-total@</p><p>
</p><p style="color: #d9534f; font-weight: bold;">Observamos que a fatura está vencida e gentilmente solicitamos a regularização do pagamento o quanto antes.</p><p>
</p><p>Se você tiver dúvidas sobre essa cobrança ou precisar de mais informações, estamos à disposição para ajudá-lo.</p><p>
</p><p>Atenciosamente,</p><p>
</p><p><strong>@razao-social-filial@</strong></p><p>@email-filial@</p><p>@telefone-filial@</p>`},
                { text: 'Aviso de vencimento próximo', value: '', assunto: 'Aviso de Vencimento Próximo', modelo: 
`<h1 style="color: #004b87;">Aviso de Vencimento Próximo</h1>
<p>Prezado(a) <strong>@nome-cliente@</strong>,</p><p>Gostaríamos de lembrá-lo de que o pagamento referente à sua fatura, com vencimento em <strong>@vencimento@</strong>, está se aproximando. O valor total da fatura é de <strong>@valor-total@</strong>.</p>
<p>Por favor, verifique as informações da sua fatura abaixo:
</p><p><strong>Forma de pagamento:</strong> @forma-pagamento@</p><p><strong>Data de vencimento:</strong> @vencimento@</p><p><strong>Valor total:</strong> @valor-total@</p>
<p style="color: #f0ad4e; font-weight: bold;">Certifique-se de realizar o pagamento até a data de vencimento para evitar juros e multa por atraso.</p>
<p>Se você tiver dúvidas sobre essa cobrança ou precisar de mais informações, estamos à disposição para ajudá-lo.</p>
<p>Atenciosamente,</p>
<p><strong>@razao-social-filial@</strong></p><p>@email-filial@</p><p>@telefone-filial@</p>`},
            ]
        }
    },
    computed: {
        menuStyles() {
            // Ajustar o menu com base nas coordenadas de clique
            return {
                position: 'absolute',
                top: `${this.menuPosition.top}px`,
                left: `${this.menuPosition.left}px`,
                zIndex: 100, // Garantir que o menu fique acima de outros elementos
            };
        },
        descricaoLembrete() {
            let msg = ''

            if (this.modelo.e_quando && this.modelo.qtde_dias) {
                if (this.modelo.qtde_dias >= 0) {
                    if (this.modelo.qtde_dias > 0) {
                        if (this.modelo.e_quando === 'A') {                    
                        msg = `${this.modelo.qtde_dias} dias antes do vencimento da fatura`
                    } 
                    if (this.modelo.e_quando === 'D') {                    
                        msg = `${this.modelo.qtde_dias} dias após o vencimento da fatura`
                    }
                    } else {
                        msg = 'No dia do vencimento da fatura.' 
                    } 
                } else {
                    msg = 'Não é permitido quantidade de dias negativo'
                }                 
            } else {
                msg = 'Selecione quando e a quantidade de dias'
            }

            return msg
        }
    },
    methods: {  
        // Adicionar campos no VueEditor (Menu de Contexto)
        onEditorReady(editor) {
            this.quill = editor
        },
        closeMenu() {
            this.showMenu = false
        },
        showContextMenu(event) {
            const editor = this.$refs.editor
            const rect = editor.getBoundingClientRect()
            this.menuPosition = {
                top: event.clientY - rect.top + window.scrollY,  // Ajusta para a posição da janela
                left: event.clientX - rect.left + window.scrollX,  // Ajusta para a posição da janela
            };
            this.showMenu = true
        },
        onSelectionChange(range) {
            if (range) this.cursor = range.index
        },
        insertText(value) {
            this.quill.insertText(this.cursor, value)
            this.quill.setSelection(this.cursor + value.length, 0)
        },
        // ************************************************        

        
        novoLembrete() {
            this.reset()
            this.mode = 'save'
            this.loadFiliais()
        },
        novoModelo() {
            this.modalLembrete = true
            this.modoModelo = 'novo'
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        novaConsulta() {
            this.page = 1
            this.loadLembretes()
        },
        loadLembretes() {
            this.showOver = true
            const url = `${baseApiUrl}/lembretesconsulta/?parcial=${this.parcial}&ativos=${this.ativos}&page=${this.page}`
            axios.get(url)
            .then(res => {
                this.showOver = false
                if (res.data) {
                    this.lembretes = res.data.data
                    this.count = res.data.data.total
                    this.limit = res.data.data.per_page
                }
            }).catch(err => {
                this.showOver = false
                showError(err)
            })
        },
        loadLembrete(l) {    
            const url = `${baseApiUrl}/lembretes/${l.id_lembrete_configuracao}`   
            axios.get(url).then(res => {
                this.lembrete = { ...res.data } 
                this.lembrete.modelos.forEach((c, i) => {
                   c.indexItem = i
                })

                this.filialSelecionada = {value: this.lembrete.id_filial, text: ` | ${this.lembrete.nome_filial}`}

                this.loadFiliais()
                this.mode = 'save'
                this.show = false
            })
            .catch(err => {
                showError(err)
            })                
        }, 

        loadFiliais() { 
            const url = `${baseApiUrl}/filiais`    

            axios.get(url).then(res => {
                this.filiais = res.data.map(f => {
                    return { ...f, value: f.idfilial, text: ` | ${f.razao_social}` }
                })
            })
            .catch(err => {
                showError(err)
            })           
        },

        save() {
            const method = this.lembrete.id_lembrete_configuracao ? 'put' : 'post'
            const url = `${baseApiUrl}/lembretes${this.lembrete.id_lembrete_configuracao ? '/' + this.lembrete.id_lembrete_configuracao : ''}`
            axios[method](url, this.lembrete)
                .then(() => {
                    this.showOver = false
                    this.reset()
                    this.$toasted.global.defaultSuccess()
                })
                .catch(showError)
        },
        desejaRemoverLembrete(l) {
            this.lembrete = { ...l }
            this.modalRemLembrete = true
        },
        remove() {
            axios.delete(`${baseApiUrl}/lembretes/${this.lembrete.id_lembrete_configuracao}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.loadLembretes()
                this.modalRemLembrete = false
            })
            .catch(err => {
                showError(err)
            })
        },
        reset() {
            this.lembrete = {
                id_lembrete_configuracao: '',
                nome_setor: '',
                email_ativar_envio: '',
                email_user: '',
                email_pass: '',
                email_user_reply: '',
                email_host: '',
                email_port: '',
                email_protocol: 'AMBOS', 
                ativo: true
            }
            this.filialSelecionada = ''
            this.show = !this.show
            this.mode = 'new'
        },

        resetModelo() {
            this.modelo = {
                descricao: '',
                e_quando: 'A',
                qtde_dias: 5,
                e_mail_assunto: '',
                e_mail_corpo: '',
                ativo: 'S'
            }

            this.modalLembrete = false
            this.modoModelo = 'new'
        },

        saveModelo() {
            if (this.modoModelo === 'novo') {
                this.lembrete.modelos.push(this.modelo)
                this.lembrete.modelos.forEach((c, i) => {
                    c.indexItem = i
                })
            }
            if (this.modoModelo === 'editar') {
                this.lembrete.modelos.splice(this.modelo.indexItem, 1, {...this.modelo})
            }

            this.resetModelo()
        },
        loadModelo(m) {
            this.modalLembrete = true
            this.modoModelo = 'editar'
            this.modelo = { ... m }
        },
        desejaRemoverModelo(m) {
            this.moledo = { ...m }
            this.modalRemModelo = true
        },
        removeModelo() {
            this.lembrete.modelos.splice(this.moledo.indexItem, 1)
            this.lembrete.modelos.forEach((c, i) => {
                c.indexItem = i
            })
            this.modalRemModelo = false
        },
    },
    watch: {
        filialSelecionada() {
            if (this.filialSelecionada) {
                this.lembrete.id_filial = this.filialSelecionada.value
            } else {
                this.lembrete.id_filial = ''
            }
        },
        modeloSelecionado() {
            if (this.modeloSelecionado) {
                this.modelo.e_mail_corpo = this.modeloSelecionado.modelo
                this.modelo.e_mail_assunto= this.modeloSelecionado.assunto
            } 
        },
    },
    mounted() {
        window.addEventListener('click', this.closeMenu);
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeMenu);
    },
    created() {
        this.loadLembretes()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    /* Vue editor (Menu contexto) ***************/    
    .editor-container {
        border: 1px solid #ccc;
        min-height: 200px;
        position: relative;
    }
    .custom-context-menu {
        position: absolute;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .custom-context-menu ul {
        list-style: none;
        margin: 0;
        padding: 10px;
    }
    .custom-context-menu li {
        cursor: pointer;
        padding: 5px 10px;
    }
    .custom-context-menu li:hover {
        background-color: #f1f1f1;
    }
    .custom-context-menu hr {
        margin: 10px 0 0 0;
    }
    .custom-context-menu-t {
        margin: 5px 5px -5px 15px;
    }
    /*******************************************/ 



    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    



</style>