<template>
    <div class="lembretes-pages">
        <PageTitle icon="fa fa-exclamation-triangle" main="Lembretes"
            sub="Configurar lembretes de vencimento de faturas entre outros" />
        
        <CadastroLembretes />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroLembretes from './CadastroLembretes.vue'

export default {
    name: 'LembretesPages',
    components: { PageTitle, CadastroLembretes }
}
</script>

<style>

</style>
