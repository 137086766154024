<template>
    <div class="cadastro-boletos">

        
        <b-tabs content-class="mt-3">
            <div class="div-topo-b">
            </div>

            <b-tab title="Boletos" active>
                <div class="acoesTopo acoesTopo-bol">             
                    <b-button variant="danger" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                        @click="reset">
                            <i class="fa fa-times button-icons-acoes"></i>
                    </b-button>
                    <b-button variant="danger" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Excluir'"
                        @click="desejaExcluirBoleto">
                            <i class="fa fa-trash button-icons-acoes"></i>
                    </b-button>
                    <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                        @click="save">
                            <i class="fa fa-check button-icons-acoes"></i>
                    </b-button> 
                    <b-button variant="success" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Opções de Boletos'"
                        @click="opcoesBoletos">
                            <i class="fa fa-cloud-download button-icons-acoes"></i>                    
                    </b-button> 
                </div> 

                <div class="containerRoll" v-if="show">
                    <b-card class="cadastros-card-pesquisa sticky-card-bol">            
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group>
                                    Localizar
                                    <b-form-input id="localizar" type="search"
                                        :formatter="upperCase"
                                        v-model="parcial"
                                        :readonly="mode === 'remove'"
                                        placeholder="Informe os dados da pesquisa..." />
                                </b-form-group>
                            </b-col> 

                            <b-col md="2" sm="12">
                                <b-form-group>
                                    Data inicial
                                    <b-form-input id="data-inicial" type="date"
                                        v-model="dataInicial"
                                        :readonly="mode === 'remove'" />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group>
                                    Data final
                                    <b-form-input id="data-final" type="date"
                                        v-model="dataFinal"
                                        :readonly="mode === 'remove'" />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label-for="bol-situacao">
                                    Situação
                                    <b-form-select 
                                        id="bol-situacao" 
                                        v-model="situacao" 
                                        :options="['TODAS','ENVIAR','SALVO','FALHA','EMITIDO','REJEITADO','REGISTRADO','LIQUIDADO','BAIXADO','PENDENTE_RETENTATIVA']" 
                                    />                                    
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12" class="d-flex justify-content-end">
                                <b-button variant="info" @click="novaConsulta">
                                    <i class="fa fa-search button-icons"></i> Consultar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="cadastros-card-corpo" v-show="show">
                        <b-table                 
                            :busy="isBusy"

                            :items="boletos" 
                            :fields="fields" 
                            :per-page="limit" 
                            :current-page="page"

                            @row-clicked="rowClicked"
                            :tbody-tr-class="tbodyRowClass"
                            primary-key="id"

                            ref="selectableTableBoletos"  
                            hover 
                            sort-icon-left 
                            small 
                            striped 
                            borderless                                
                            responsive
                        >  

                            <template #head(selected) v-if="boletos.length > 0">                                    
                                <template v-if="boletos.length === boletosSelecteds.length">
                                    <div style="margin-bottom: -5px">
                                        <span  aria-hidden="true" @click="clearSelectedBoletos"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                                    </div>
                                </template>
                                <template v-if="boletos.length !== boletosSelecteds.length"> 
                                    <div style="margin-bottom: -5px">
                                        <span aria-hidden="true" @click="selectAllRowsBoletos"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                                    </div>
                                </template>
                            </template>

                            <template v-slot:cell(selected)="{ item, field: { key } }" >
                                <span v-if="item[key]"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                                <span v-else><i style="font-size: 23px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                            </template>

                            <template #table-busy>
                                <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle mr-1"></b-spinner>
                                <strong>Aguarde...</strong>
                                </div>
                            </template>

                            <template #cell(actions)="data">
                                <b-button size="sm" @click="toggleDetails(data.item)" class="mr-2">
                                    <i class="fa fa-chevron-down"></i>
                                </b-button>
                                <b-button variant="success" @click="loadBoleto(data.item)" class="mr-2" v-if="false">
                                    <i class="fa fa-cloud-download"></i>
                                </b-button>
                            </template>

                            <template #row-details="row">
                                <b-card no-body class="p-2">
                                    <b-row>
                                        <b-col md="8" sm="12">
                                            <p style="margin-bottom: -10px"><strong>Motivos</strong></p>
                                        </b-col>
                                        <b-col md="4" sm="12" class="d-flex justify-content-end">
                                            <b-button :disabled="row.item.situacao ==='FALHA' || row.item.situacao ==='ENVIAR'" size="sm" variant="info" @click="verificarStatus(row.item)" class="m-1">
                                                <i class="fa fa-refresh button-icons"></i> Verificar Status
                                            </b-button>
                                            <b-button :disabled="row.item.situacao !== 'ENVIAR'" size="sm" variant="info" @click="reenviar(row.item)" class="m-1">
                                                <i class="fa fa-arrow-up button-icons"></i> Reenviar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    
                                    <hr>
                                    <b-table 
                                        :busy="isBusyMotivos" 
                                        stacked                             
                                        borderless 
                                        small 
                                        :items="row.item.motivo"
                                    >    
                                        <template #table-busy>
                                            <div class="text-center text-primary my-2">
                                            <b-spinner class="align-middle mr-1"></b-spinner>
                                            <strong>Aguarde...</strong>
                                            </div>
                                        </template>                        
                                    </b-table>
                                </b-card>                    
                            </template>
                        </b-table>
                        <hr>
                        <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
                        
                    </b-card> 
                </div>      

                <b-overlay :show="showOver" rounded="sm">
                    <b-card class="cadastros-card-corpo" v-show="!show">
                        <b-form>
                            <input id="boletos-id" type="hidden" v-model="boleto.id_boleto_emitido" />

                            <b-row>                       
                                <b-col md="6" sm="12">
                                    <b-form-group label="Descrição" label-for="boletos-descricao"> 
                                        <b-form-input id="boletos-descricao" type="text"
                                            :formatter="upperCase"
                                            v-model="boleto.descricao" required
                                            :readonly="mode === 'remove'"
                                            placeholder="Informe a descrição do boleto..." />
                                    </b-form-group>
                                </b-col> 
                            </b-row>

                            <b-row>    
                                <b-col md="4" sm="12">
                                    <b-form-group label="Custo" label-for="boleto-custo">
                                        <money v-model="boleto.custo" v-bind="money" class="form-control"/>
                                    </b-form-group>
                                
                                    <b-form-group label="Preço" label-for="boleto-preco">
                                        <money v-model="boleto.preco" v-bind="money" class="form-control"/>
                                    </b-form-group>

                                    <b-form-group label="Código NBS" label-for="boletos-nbs"> 
                                        <b-form-input id="boletos-nbs" type="text"
                                            v-model="boleto.cod_nbs" 
                                            :readonly="mode === 'remove'"/>
                                    </b-form-group>
                                </b-col>                          
                            </b-row>  

                            <b-row>                 
                                <b-col md="12" sm="12">
                                    <b-form-group label="Motivos" label-for="boleto-detalhes">
                                        <VueEditor v-model="boleto.motivo" />
                                    </b-form-group>
                                </b-col>               
                            </b-row>
                            
                            <b-card class="cadastros-opcoes-row-card">
                                <b-row class="cadastros-opcoes-row" align-h="around">                            
                                    <b-col md="2" sm="12">
                                        <b-form-checkbox id="boletos-ativo" checked v-show="mode === 'save'" v-model="boleto.ativo" class="mt-3 mb-3" switch>Ativo</b-form-checkbox>
                                    </b-col>                            
                                </b-row>
                            </b-card>
                            
                            <br>
                            <b-row>
                                <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                                    <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                                    <b-popover target="popover-target-1" triggers="hover" placement="top">
                                        <template #title>Alterações</template>
                                        <b>Usuário Cadastro:</b> {{boleto.usu_cad}} <br>
                                        <b>Data de Cadastro:</b> {{boleto.data_cad}} <br>
                                        <b>Usuário Alteração:</b> {{boleto.usu_alt}} <br>
                                        <b>Data de Alteração:</b> {{boleto.data_alt}}
                                    </b-popover>                        
                                </b-col>

                            </b-row>

                        </b-form>
                    </b-card>
                </b-overlay>   

                <div v-show="!show" style="margin-top: 10px">
                        <div class="acoesRodape">                 
                        <b-button variant="danger" class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.topleft="'Cancelar'"
                            @click="reset">
                                <i class="fa fa-times button-icons-acoes"></i>
                        </b-button>
                        <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'" v-b-tooltip.hover.topleft="'Excluir'"
                            @click="remove">
                                <i class="fa fa-trash button-icons-acoes"></i>
                        </b-button>
                        <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" v-b-tooltip.hover.topleft="'Salvar'"
                            @click="save">
                                <i class="fa fa-check button-icons-acoes"></i>
                        </b-button>  
                    </div>
                </div> 
            </b-tab>

            <b-tab title="Remessas" class="tab-topo-bol">
                <b-row> 
                    <b-col md="12" sm="12">
                        <b-form-group label="Filial" label-for="conta-bancaria-filial">
                            <b-form-select id="conta-bancaria-filial" v-model="paramBoletos.filial" :options="filiais" required/>
                        </b-form-group>
                        <b-form-group label="Conta Bancária" label-for="conta-bancaria">
                            <b-form-select id="conta-bancaria" v-model="paramBoletos.conta" :options="contasBancarias"/>
                        </b-form-group>
                        <b-form-group label="Convênio" label-for="conta-bancaria-conv">
                            <b-form-select id="conta-bancaria-conv" v-model="paramBoletos.convenio" :options="contasBancariasConv" :disabled="contasBancariasConv.length < 1"/>
                        </b-form-group>
                    </b-col> 
                </b-row>
                <br>

                <b-row>
                    <b-col md="12" sm="12">
                            <b-button variant="success" @click="baixarRemessa()"><i class="fa fa-cloud-download mr-2" aria-hidden="true"></i>Baixar Remessa</b-button>
                    </b-col> 
                </b-row>
            </b-tab>

            <b-tab title="Retornos" class="tab-topo-bol">
                <b-overlay :show="overlayRetorno" rounded="sm">
                    <b-row style="margin-top: -45px"> 
                        <b-col md="10" sm="12">                        
                            <br>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Arquivo" label-for="conta-bancaria-arq">
                                        <b-form-file
                                        id="conta-bancaria-arq"
                                            browse-text="Selecionar"
                                            v-model="arqRetorno"
                                            placeholder="Selecione um arquivo ou arraste e solte aqui..."
                                            drop-placeholder="Solte o arquivo aqui..."
                                        ></b-form-file>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col md="6" sm="12">
                                    <b-form-group label="Conta Bancária" label-for="conta-bancaria">
                                        <b-form-select id="conta-bancaria" v-model="contaRetorno" :options="contasBancarias"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <b-form-group label="Caixa para liquidação dos títulos" label-for="tags-with-dropdown">
                                        <b-form-tags id="tags-with-dropdown" v-model="value" no-outer-focus class="mb-2">
                                            <template v-slot="{ tags, disabled }"> 
                                                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                                    <template #button-content>
                                                        <b-icon icon="tag-fill"></b-icon> Escolher caixa
                                                    </template>

                                                    <b-dropdown-form @submit.stop.prevent="() => {}">
                                                        <b-form-group
                                                            label="Procurar"
                                                            label-for="tag-search-input"
                                                            label-cols-md="auto"
                                                            class="mb-0"
                                                            label-size="sm"
                                                            :description="searchDesc"
                                                            :disabled="disabled"
                                                        >
                                                            <b-form-input
                                                                v-model="search"
                                                                id="tag-search-input"
                                                                type="search"
                                                                size="sm"
                                                                placeholder="Informe um nome ou uma data..."
                                                                autocomplete="off"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-dropdown-form>
                                                    <b-dropdown-divider></b-dropdown-divider>

                                                    <b-overlay :show="overlaySearch" rounded="sm">
                                                        <!-- Exibe as opções disponíveis -->
                                                        <b-dropdown-item-button v-for="option in availableOptions" :key="option.id_caixa" @click="onOptionClick(option)" v-show="!option.disabled">
                                                            <b-card no-body class="p-2" >
                                                                <strong>{{ option.data }}</strong>
                                                                {{ option.nome }}
                                                            </b-card>
                                                        </b-dropdown-item-button>

                                                        <b-dropdown-text v-if="availableOptions.length === 0">
                                                        Não há opções disponíveis para selecionar
                                                        </b-dropdown-text>
                                                    </b-overlay>
                                                </b-dropdown>
                                                <br>                                               

                                                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                                        <div style="font-size: 1.3rem;">
                                                            <b-form-tag
                                                                @remove="onRemoveTag()"
                                                                :title="tag"
                                                                :disabled="disabled"
                                                                variant="warning"
                                                                class="p-3"
                                                            >
                                                                {{tag}}
                                                            </b-form-tag>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </template>
                                        </b-form-tags>
                                    </b-form-group>
                                </b-col>
                            </b-row>  
                            <br>                                
                        </b-col>
                        <b-col md="2" sm="12" class="text-right">
                            <b-button variant="success" @click="enviarRetorno()" style="margin-top: 57px; padding: 20px; font-size: 1.2rem;">
                                <i class="fa fa-cloud-upload mr-2" aria-hidden="true"></i>Enviar Retorno
                            </b-button> 
                        </b-col>
                    </b-row>
                    <br>       

                    <template>
                        <b-table responsive hover small striped :items="retornos" :fields="fieldsRetornos" sort-icon-left>                         
                            <template v-slot:cell(status)="data">
                            <div style="font-size: 1.2rem">
                                <b-badge class="ml-2" v-if="data.item.status == 'P'" variant="success" v-b-tooltip.hover.right="'Processado'">{{data.item.status}}</b-badge>
                                <b-badge class="ml-2" v-if="data.item.status == 'F'" variant="danger" v-b-tooltip.hover.right="'Falha'">{{data.item.status}}</b-badge>
                                <b-badge class="ml-2" v-if="data.item.status == 'E'" variant="warning" v-b-tooltip.hover.right="'Enviado'">{{data.item.status}}</b-badge>
                            </div>
                            </template>
                            <template v-slot:cell(actions)="data">
                            <b-button variant="success" @click="verificarRetorno(data.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Verificar status'">
                                <i class="fa fa-refresh"></i>
                            </b-button>
                            </template>
                        </b-table>
                    </template>

                    <template #overlay>
                        <div class="text-center">
                            <i style="font-size: 2rem" class="fa fa-circle-o-notch spinner-overlay"></i>
                            <p style="font-size: 1.2rem" id="cancel-label">{{mensagemImpressao}}</p>                        
                        </div>
                    </template>
                </b-overlay>
            </b-tab>
        </b-tabs>

        <b-modal id="modal-remove-boleto" title="Excluir boletos" v-model="modalExcluirBoleto"   >
            <p class="my-4">Deseja realmente excluir os boletos selecionados?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-remove-boleto')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal>

        <b-modal 
            hideBvModalEvent
            v-model="modalLoadBoleto"                        
            id="modal-load-boleto" 
            centered size="lg" 
            ok-only
            ok-disabled
            title="Opções de Boletos"
        >  
            <div style="min-height: 428px">
                <b-tabs content-class="mt-3" fill>
                    <b-tab title="Download do PDF" active>
                        <b-row>
                            <b-col md="9" sm="12" lg="9">
                                <b-container fluid class="p-4 bg-light option-boletos">
                                    <b-row>
                                        <b-col>
                                            <b-img v-show="dadosImpressao.TipoImpressao === '0'" thumbnail fluid src="../../../../thumbsBoletos/layout_0.jpg" alt="Layout 0"></b-img>
                                            <b-img v-show="dadosImpressao.TipoImpressao === '1'" thumbnail fluid src="../../../../thumbsBoletos/layout_1.jpg" alt="Layout 1"></b-img>
                                            <b-img v-show="dadosImpressao.TipoImpressao === '2'" thumbnail fluid src="../../../../thumbsBoletos/layout_2.jpg" alt="Layout 2"></b-img>
                                            <b-img v-show="dadosImpressao.TipoImpressao === '3'" thumbnail fluid src="../../../../thumbsBoletos/layout_3.jpg" alt="Layout 3"></b-img>
                                            <b-img v-show="dadosImpressao.TipoImpressao === '4'" thumbnail fluid src="../../../../thumbsBoletos/layout_4.jpg" alt="Layout 4"></b-img>
                                            <b-img v-show="dadosImpressao.TipoImpressao === '99'" thumbnail fluid src="../../../../thumbsBoletos/layout_0.jpg" alt="Layout 0"></b-img>
                                        </b-col>
                                        <b-col>
                                            <b-form-group label="Opções de Impressão" v-slot="{ ariaDescribedby }">
                                                <b-form-radio-group
                                                    v-model="dadosImpressao.TipoImpressao"
                                                    :options="optionsImpressao"
                                                    :aria-describedby="ariaDescribedby"
                                                    name="radios-stacked"
                                                    stacked
                                                ></b-form-radio-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-container>                                
                            </b-col>

                            <b-col md="3" sm="12" lg="3">
                                <b-button variant="success" @click="imprimirBoletos()"><i class="fa fa-cloud-download mr-2" aria-hidden="true"></i>Download PDF</b-button>
                            </b-col>                            

                        </b-row>
                    </b-tab>
                </b-tabs>
            </div>
            
            <br> 

            <b-card style="width: 100%" v-if="false">
                <vue-pdf-embed 
                :source="boletoPdf" />
            </b-card>   

            <b-card style="width: 100%">
                <div class="text-center text-primary my-2">
                    <b-spinner v-if="statusImpressao === 'sucesso'" class="align-middle mr-1"></b-spinner>
                    <strong>{{ mensagemImpressao }}</strong>
                </div>            
            </b-card> 
            
            <template #modal-footer>
                <div class="w-100"> 
                </div>
            </template>                  
        </b-modal> 
    </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { baseApiUrl, showError, showSuccess } from '@/global'
import {VMoney} from 'v-money'
import { VueEditor } from "vue2-editor"
const moment = require('moment')
import axios from 'axios'

export default {
    name: 'CadastroBoletos',
    components: { VueEditor, VuePdfEmbed },
    computed: {
        selectedRows() {
            return this.boletos.filter(item => item.selected)
        },

        criteria() {
            return this.search.trim().toLowerCase()
        },
        availableOptions() {
            const criteria = this.criteria
            const options = this.options.filter(opt => !this.value.includes(opt.nome))

            if (criteria) {
                return options.filter(opt =>
                    opt.nome.toLowerCase().includes(criteria) || opt.data.includes(criteria)
                );
            }

            return options
        },
        searchDesc() {
            if (this.criteria && this.availableOptions.length === 0) {
                return 'Não há opções que correspondem ao critério de busca'
            }
            return ''
        }
    },
    data: function() {
        return {
            options: [],
            search: '',
            value: [],
            value2: {},
            debounceTimeout: null, // Variável para armazenar o timeout
            overlaySearch: false,

            overlayRetorno: false,

            testes: [],
            teste2: [],
            selecteds: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mode: 'new',
            boleto: {
                id_boleto: '',
                ativo: true,
            },
            modalLoadBoleto: false,
            modalExcluirBoleto: false,
            dadosImpressao: {
                TipoImpressao : "0",
                Boletos : []
            },
            loteImpressao: [],
            statusImpressao: '',
            mensagemImpressao: '',             
            optionsImpressao: [
                { text: 'Normal', value: '0', disabled: false },
                { text: 'Carnê duplo (paisagem)', value: '1', disabled: false },
                { text: 'Carnê triplo (retrato)', value: '2', disabled: false },
                { text: 'Duplo (retrato)', value: '3', disabled: false },
                { text: `Normal (Com marca D'água)`, value: '4', disabled: false },
                { text: 'Personalizado', value: '99', disabled: true }
            ],
            filiais: [],
            contasBancarias: [],
            contasBancariasConv: [],
            loteRemessa: [],
            boletosSelecteds: [],
            idsBoletos: [],
            boletosSelecionados: [],
            paramBoletos: {
                conta: 0,
                filial: 0,
                bol: []
            },
            situacaoRetorno: '',
            arqRetorno: [],
            arqBase64: '',
            contaRetorno: 0,
            boletoPdf: '',
            boletos: [],
            emissao:{},
            retornos: [],
            retorno: {},
            isBusy: false,
            isBusyMotivos: false,
            page: 1,
            count: 0,
            limit: 0,
            unidades: [],            
            dataInicial: moment().add(-1, 'months').format('YYYY-MM-DD'),
            dataFinal: moment().format('YYYY-MM-DD'),
            situacao: 'TODAS',
            parcial: '',
            ativos: 'S',
            show: true,
            showOver: false,
            fields: [
                {key: 'selected', label: '', sortable: false},
                {key: 'id_boleto_emitido', label: 'Código', sortable: true}, 
                {key: 'titulo_nosso_numero', label: 'Nosso Número', sortable: true}, 
                {key: 'titulo_data_emissao', label: 'Data', sortable: true,
                    formatter: value => {
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'titulo_data_vencimento', label: 'Vencimento', sortable: true,
                    formatter: value => {
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'sacado_nome', label: 'Nome/Razão Social', sortable: true},
                {key: 'titulo_valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'situacao', label: 'Situação', sortable: true},                 
                {key: 'actions', label: ''}
            ], 

            fieldsRetornos: [  
                {key: 'status', label: 'Status', sortable: true},              
                {key: 'data_cad', label: 'Data', sortable: true,
                    formatter: value => {
                        return moment(value).format('DD/MM/YYYY HH:MM:SS')
                    }
                },
                {key: 'nome_arquivo', label: 'Arquivo', sortable: true}, 
                {key: 'protocolo', label: 'Protocolo', sortable: true},
                {key: 'motivo', label: 'Mensagem', sortable: true},               
                {key: 'actions', label: ''}
            ],       
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        onOptionClick(option) {            
            this.value2 = { ...option }
            this.value = `${this.value2.data} - ${this.value2.nome}`
            if(this.value2.id_caixa) {
                this.contaRetorno.id_caixa = this.value2.id_caixa
                this.options.forEach(i=>{
                    if (i.id_caixa == this.value2.id_caixa) {
                        i.disabled = true
                    } else {
                        i.disabled = false
                    }
                })               
            }
        },

        onRemoveTag() {
            this.options.forEach( i=>{
                if (i.id_caixa == this.value2.id_caixa) i.disabled = false
            }) 
            this.value = []
            this.value2 = {}
            this.contaRetorno.id_caixa = null
        },

        loadFiliais() { 
            const url = `${baseApiUrl}/filiais`    

            axios.get(url).then(res => {
                 this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social} (${filial.fantasia})`}
                })
            })
            .catch(err => {
                showError(err)
            })            
        },

        loadContasBancarias() { 
            const url = `${baseApiUrl}/contas_bancarias/consulta/xxxxxxxxxx/ativos/S/`    

            axios.get(url).then(res => {
                 this.contasBancarias = res.data.data.map(conta => {
                    return { ...conta,  value: conta.id_conta_bancaria, text: `${conta.nome_conta} ( Conta: ${conta.conta}-${conta.conta_dv} | Agência: ${conta.agencia}-${conta.agencia_dv} )`}
                })

                this.contasBancarias.unshift({value: 0, text: 'SELECIONE'})
            })
            .catch(err => {
                showError(err)
            })            
        },

        loadCaixas() {
            axios.get(`${baseApiUrl}/caixaretorno`, {
                params: {
                    search: this.search
                }
            })
            .then(res => {
                this.options = res.data
                this.overlaySearch = false;
            })
            .catch(err => {
                this.options
                showError(err)
            })   
        },

        loadConvenios() {
            if (this.paramBoletos.conta > 0) {
                const url = `${baseApiUrl}/convenios_bancarios/${this.paramBoletos.conta}`    
    
                axios.get(url).then(res => {
                     this.contasBancariasConv = res.data.map(conv => {
                        return { ...conv,  value: conv.id_conta_bancaria_convenio, text: `${conv.descricao} ( Nº Convênio: ${conv.numero} )`}
                    })
                })
                .catch(err => {
                    showError(err)
                }) 
            }
        },
        async opcoesDownBoleto() {
            let cont = 0                    

            for (const l of this.loteImpressao) {

                cont = cont+1

                this.emissao = {
                    ambiente:l.ambiente, 
                    token: l.token, 
                    url: l.url,
                    cnpjCedente: l.cnpjCedente,
                    cnpjSh: l.cnpjSh
                } 

                this.dadosImpressao.Boletos = [ ...l.boletos ]

                this.statusImpressao = 'sucesso'
                this.mensagemImpressao = `Solicitando impressão do ${cont}º lote`

                axios.defaults.headers.common['token-sh'] = this.emissao.token                
                axios.defaults.headers.common['cnpj-cedente'] = this.emissao.cnpjCedente                
                axios.defaults.headers.common['cnpj-sh'] = this.emissao.cnpjSh                
                await axios({
                    url: `${this.emissao.url}`,
                    method: 'POST',
                    data: { ...this.dadosImpressao }
                })
                .then(async (r) => {   
                    if (r.data._status === 'sucesso' ) {
                        this.emissao.protocolo = r.data._dados.protocolo 

                        this.statusImpressao = 'sucesso'
                        this.mensagemImpressao = 'Impressão em processamento.'
                        
                        this.downloadPdf()
                    } 
                    if (r.data._status === 'erro' ) {
                        showError(r.data._mensagem || 'Ops! Erro desconhecido.')
                    } 
                })
                .catch(err => {
                    showError(err.response.data._mensagem || err.response || 'Ops! Erro desconhecido.')
                })      
            
            }

        },
        opcoesBoletos() {
            this.modalLoadBoleto = true
            this.loadContasBancarias()
            this.loadFiliais()
        },

        async reenviar(bol){

            // this.overGerBoleto = true

            let ids = []
            ids.push(bol.id_boleto_emitido)

            const paramBoletos = {
                reenviar: true,
                idsBoletos: [ ...ids ],
                filialContaBancaria: bol.id_filial
            }

            axios['post'](`${baseApiUrl}/gerarboleto/emitir`, paramBoletos)
            .then(() => {
                // this.overGerBoleto = false                        
                showSuccess('Boletos gerados com sucesso.')
                // this.modalEmiteBoleto = false
            })
            .catch(err => {
                // this.overGerBoleto = false
                showError(err)
            })
            
        },
        imprimirBoletos() {

            if (this.boletosSelecionados.length < 1) {

                showError('Selecione ao menos um boleto para download!')

            } else {
                let aEnviar = false

                for (let b of this.boletosSelecionados) {
                    const blt = this.boletosSelecteds.find((bol) => (bol.id_boleto_emitido === b) );
                    const s = blt.situacao
                    if (s === 'ENVIAR' || s === 'SALVO' || s === 'FALHA' || s === 'PENDENTE_RETENTATIVA') { aEnviar = true }
                }

                if (aEnviar) {
                    showError('Não é possível fazer download de boletos com as situações: <br> ENVIAR, SALVO, FALHA, PENDENTE_RETENTATIVA')
                } else {
                    
                    axios['get'](`${baseApiUrl}/imprimirboletos/?idsBoletos=${this.boletosSelecionados}`)
                        .then(async res => {
                            if (res.data) {
                                this.loteImpressao = [ ...res.data ] 
                                this.opcoesDownBoleto()
                            }
                        }) 
                    .catch(err => {
                        showError(err)
                    })
                }
            }

        },
        async baixarRemessa() {

            if (this.boletosSelecionados.length < 1) {

                showError('Selecione ao menos um boleto para gerar e baixar a remessa!')

            } else {

                if (this.paramBoletos.filial < 1) {
                    showError('Selecione a filial!')
                } else {
                    axios['get'](`${baseApiUrl}/baixarremessa/?idsBoletos=${this.boletosSelecionados}&conta=${this.paramBoletos.conta}&convenio=${this.paramBoletos.convenio}&filial=${this.paramBoletos.filial}`)
                        .then(async res => {
    
                            if (res.data) {
    
                                this.loteRemessa = [ ...res.data ]
    
                                for (const rt of this.loteRemessa) {
    
                                    axios.defaults.headers.common['token-sh'] = rt.token                
                                    axios.defaults.headers.common['Content-Type'] = 'application/json'               
                                    axios.defaults.headers.common['cnpj-cedente'] = rt.cnpjCedente                
                                    axios.defaults.headers.common['cnpj-sh'] = rt.cnpjSh 
                                    await axios({
                                        url: `${rt.url}`,
                                        method: 'POST',
                                        data: rt.boletos
                                    })
                                    .then(async (r) => { 

                                        this.testes = r
                                                                        
                                        if (r.data._status !== 'sucesso' ) { 
                                            
                                            this.statusImpressao = r.data._status        
                                            this.mensagemImpressao = 'Falha na geração da remessa!'
                                            showError('Falha na geração da remessa!') 
        
                                        } else {
        
                                            if (r.data._dados._sucesso.length < 1 ) {
                                                this.statusImpressao = 'falha'
                                                this.mensagemImpressao = r.data._dados._falha[0].mensagem
                                                
                                            } else {  
                                                for (const rm of r.data._dados._sucesso) {
                                                    const nomeArq = {
                                                        banco: Number(rm.CedenteContaCodigoBanco),
                                                        cedente: rt.codCedente,
                                                        data: moment().format('DD/MM')
                                                    }

                                                    const blobRem = Buffer.from(rm.remessa, 'base64').toString()
                                                    const url = window.URL.createObjectURL(new Blob([blobRem]))
                                                    const link = document.createElement('a');  
                                                    link.href = url
                                                    link.setAttribute('download', `${this.gerarNomeArquivo(nomeArq)}`);
                                                    document.body.appendChild(link);
                                                    link.click();
                                                }          
        
                                                this.statusImpressao = 'concluido'
                                                this.mensagemImpressao = 'Remessa gerada com sucesso! Verifique seus downloads.'
                                            }
        
                                        }
                                        
                                    })
                                    .catch(({ response }) => { 
                                        this.testes = response
                                        this.statusImpressao = response.data._status        
                                        this.mensagemImpressao = response.data._mensagem      
                                        showError(response.data._mensagem  )
                                    })
                                                                
                                }
        
                            }
                        }) 
                    .catch(err => {
                        this.testes = err
                        showError(err)
                    })
                }

            }

        },
        gerarNomeArquivo(a) {
             
            // Mapeamento padrão SICREDI
            const meses = {
                '01': '1',  // Janeiro
                '02': '2',  // Fevereiro
                '03': '3',  // Março
                '04': '4',  // Abril
                '05': '5',  // Maio
                '06': '6',  // Junho
                '07': '7',  // Julho
                '08': '8',  // Agosto
                '09': '9',  // Setembro
                '10': 'O',  // Outubro
                '11': 'N',  // Novembro
                '12': 'D'   // Dezembro
            };

            const [dia, mes] = a.data.split('/')
            const mesCodigo = meses[mes]
            if (!mesCodigo) {
                throw new Error("Mês inválido fornecido.")
            }
            
            let nomeArquivo = `${a.cedente}${mesCodigo}${dia}`

            if(a.banco === 136){
                nomeArquivo = nomeArquivo + moment().format('HHmmss')
            }

            nomeArquivo = nomeArquivo+'.rem'

            return nomeArquivo;
        },

        loadBoleto(bl) {  
            const url = `${baseApiUrl}/gerarboleto/consulta/${bl.id_boleto_emitido}`

            axios.get(url).then(async res => {
                this.boleto = res.data.data

                this.loteImpressao = [{
                    ambiente: res.data.ambiente, 
                    token: res.data.token, 
                    url: res.data.url,
                    cnpjCedente: res.data.cnpjCedente,
                    cnpjSh: res.data.cnpjSh,
                    boletos: [ this.boleto.id_externo ] 
                }]

                this.modalLoadBoleto = true
            }) 
            .catch(err => {
                showError(err)
            })           
        },
        downloadPdf() {
            axios.defaults.headers.common['token-sh'] = this.emissao.token                
            axios.defaults.headers.common['cnpj-cedente'] = this.emissao.cnpjCedente                
            axios.defaults.headers.common['cnpj-sh'] = this.emissao.cnpjSh 
            axios({
                url: `${this.emissao.url}/${this.emissao.protocolo}`,
                method: 'GET',
                responseType: 'blob',
            })
            .then(async (r) => {  
                if (r.data._status === 'erro' ) {
                    this.statusImpressao = r.data._status

                    this.mensagemImpressao = `Falha na geração do impresso! ${r.data._mensagem}`
                    showError(r.data._mensagem || 'Ops! Erro desconhecido.') 

                } else {

                    if (r.data._status === 'sucesso' ) {
                        this.statusImpressao = r.data._status
                        this.mensagemImpressao = r.data._mensagem +'.' 
    
                        setTimeout(() => {
                            this.downloadPdf()
                        }, 5000)
                         
                    } else {            
                        const url = window.URL.createObjectURL(new Blob([r.data]))
                        const link = document.createElement('a');                
                        link.href = url
                        link.setAttribute('download', `BOLETO_PROT_${this.emissao.protocolo}.pdf`);
                        document.body.appendChild(link);
                        link.click();

                        // window.open(url, '_blank')

                        this.statusImpressao = 'concluido'
                        this.mensagemImpressao = 'Impresso gerado! Cheque seus downloads.'
                    }

                }
                
            })
            .catch(err => {
                showError(err) 
            })        
        },
        async toggleDetails(item) {            
            
            if (item["_showDetails"]) {
                item["_showDetails"] = false
                this.$set(item, "_rowVariant", 'light')
            }
            else {
                
                // const url = `${baseApiUrl}/gerarnfse/eventos/${item.id_nfse_emitida}` 

                // axios.get(url).then(res => {
                    // item.eventos = res.data
                    
                    this.boletos.forEach(it => {
                        this.$set(it, "_showDetails", false)
                        this.$set(it, "_rowVariant", 'light')
                    })

                    this.$set(item, "_showDetails", true)
                    this.$set(item, "_rowVariant", 'warning')
                // }) 
                // .catch(err => {
                //     showError(err)
                //     item.eventos = []
                // })                 
            }
        },
        verificarStatus(item) {
            const mot =  {...item.motivo}
            const boletos = []
            item.motivo = []

            boletos.push(item.id_externo)
            
            this.isBusyMotivos = true

            const url = `${baseApiUrl}/gerarboleto/verificar`

            axios.get(url, {
                params: {
                    idsBoletos: boletos
                }
            })
            .then(async res => {

                if (res.data) {
                    res.data.forEach(it=> {
                        this.boletos.forEach(b => {
                            if (it.id_externo === b.id_externo) {
                                b.situacao = it.situacao
                                b.motivo.push(JSON.parse(it.motivo))
                            }
                        })
                    })
                }
                
                this.isBusyMotivos = false
            }) 
            .catch(err => {
                showError(err)
                item.motivo = {...mot}
                this.isBusyMotivos = false
            })
        },
        upperCase(value) {
            return value.toUpperCase()
        },

        lowerCase(value) {
            return value.toLowerCase()
        },
        
        novaConsulta() {
            this.page = 1 
            this.loadBoletos()
        },

        loadBoletos() {
            this.isBusy = true
            const url = `${baseApiUrl}/gerarboleto/consulta/?page=${this.page}&dataInicio=${this.dataInicial}&dataFim=${this.dataFinal}&parcial=${this.parcial}&situacao=${this.situacao}` 
            axios.get(url).then(res => { 
                this.boletos = [ ...res.data.data ] 
                this.boletosSelecteds = []
                this.boletosSelecionados = []
                this.boletos.forEach(b=> {
                    try {
                        b.motivo = b.motivo ? [JSON.parse(b.motivo)] : []                        
                    } catch (err) {
                       b.motivo = `${b.motivo}`
                    }
                })             
                this.count = res.data.count 
                this.limit = res.data.limit  
                this.isBusy = false              
            }) 
            .catch(err => {
                showError(err)
                this.boletos = []
                this.isBusy = false
            })
        },

        reset() {
            this.mode = 'new'
            
            this.boleto = {
                id_boleto_emitido: '',
                descricao: '',
                id_unidade_medida: '',
                id_grupo_icms_ipi: '',
                id_grupo_pis_cofins: '',
                custo: 0,
                preco:0
            }

            this.boleto.ativo = true

            this.loadBoletos()
            this.show = !this.show
        },
        save() {
            const method = this.boleto.id_boleto_emitido ? 'put' : 'post'
            const idboleto = this.boleto.id_boleto_emitido ? `/${this.boleto.id_boleto_emitido}` : ''
            this.boleto.ativo == true ? this.boleto.ativo='S' : this.boleto.ativo='N'            

            axios[method](`${baseApiUrl}/boletos${idboleto}`, this.boleto)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)

            this.boleto.ativo == 'S' ? this.boleto.ativo = true : this.boleto.ativo = false
            
        },
        desejaExcluirBoleto() {
            if (this.boletosSelecionados.length < 1) {
                showError('Selecione ao menos um boleto para exclusão!')

            } else {
                this.modalExcluirBoleto = true
            }
        },
        remove() {
            axios['delete'](`${baseApiUrl}/boletos/?idsBoletos=${this.boletosSelecionados}`)
                .then(() => {
                    const pl = this.boletosSelecionados.length > 1 ? 's' : ''
                    showSuccess(`Boleto${pl} excluído${pl} com sucesso!`)
                    this.modalExcluirBoleto = false
                    this.loadBoletos()
                }) 
            .catch(err => {
                showError(err)
                this.modalExcluirBoleto = false
            })
        },       
        loadTributacoes() { 
            const url = `${baseApiUrl}/tributacoes`
            axios.get(url).then(res => {
                this.tributacoes = res.data.map(trib => {
                    return { ...trib, value: trib.id_tributacao, text: `${trib.id_tributacao} | ${trib.descricao}` }
                })
            })
        },
        loadGruposIcmsIpi() {
            const url = `${baseApiUrl}/gruposicmsipi`
            axios.get(url).then(res => {
                this.gruposIcmsIpi = res.data.map(grupoIcmsIpi => {
                    return { ...grupoIcmsIpi, value: grupoIcmsIpi.id_grupo_icms_ipi, text: grupoIcmsIpi.descricao }
                })
            })
        },
        loadGruposPisCofins() {
            const url = `${baseApiUrl}/grupospiscofins`
            axios.get(url).then(res => {
                this.gruposPisCofins = res.data.map(grupoPisCofins => {
                    return { ...grupoPisCofins, value: grupoPisCofins.id_grupo_pis_cofins, text: grupoPisCofins.descricao }
                })
            })
        },
        loadUnidades() {
            const url = `${baseApiUrl}/unidadesmedida`
            axios.get(url).then(res => {
                this.unidadesMedida = res.data.map(unidade => {
                    return { ...unidade, value: unidade.id_unidade_medida, text: `${unidade.und} | ${unidade.descricao}` }
                })
            })
        },
        async getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
                };
                reader.onerror = error => reject(error);
            });
        },

        verificarRetorno(item) {
            this.retorno = { ...item }
            this.statusRetorno(item.id_conta_bancaria, item.protocolo)
        },
        async statusRetorno(conta, prot) { 

            if (conta < 1) {

                showError('Selecione a conta bancária')

            } else {

                axios['get'](`${baseApiUrl}/contasbancariasretorno/${conta}`)
                .then(async res => {
                    if (res.data) {                            
                        const conf = { ...res.data }

                        axios.defaults.headers.common['token-sh'] = conf.token                
                        axios.defaults.headers.common['cnpj-cedente'] = conf.cnpjCedente                
                        axios.defaults.headers.common['cnpj-sh'] = conf.cnpjSh                
                        await axios({
                            url: `${conf.url}/${prot}`,
                            method: 'GET'
                        })
                        .then(async (r) => {
                            if (r.data._status === 'sucesso') {
                                if (r.data._dados.situacao === 'PROCESSADO') {
                                    this.statusImpressao = r.data._dados.situacao
                                    this.mensagemImpressao = 'Arquivo enviado e validado com sucesso.'
                                    this.situacaoRetorno = r.data._dados.situacao 
                                    this.retorno.status = 'P'
                                    let idsBol = []
                                    let idsBolString = ''
                                    if (r.data._dados.titulos) {
                                        for (const t of r.data._dados.titulos) {
                                            idsBol.push(t.idIntegracao)
                                        }

                                        idsBolString = JSON.stringify(idsBol)

                                        this.retorno.idsBoletos = idsBolString
                                    } 
                                } else {
                                    r.data._dados.situacao === 'FALHA' ? this.retorno.status = 'F' : this.retorno.status = 'E'

                                    this.statusImpressao = r.data._dados.situacao
                                    this.mensagemImpressao = r.data._dados.mensagem
                                    this.situacaoRetorno = r.data._dados.situacao  
                                    
                                }                          
            
                            } else {
                                this.statusImpressao = r.data._status
                                this.mensagemImpressao = r.data._mensagem
                            }

                            if (this.retorno.id_boleto_retorno) {
                                if (!this.retorno.id_caixa > 0) {
                                    if (!this.value2.id_caixa) {
                                        showError('Este retorno não possui caixa. Selecione um caixa para liquidação.');
                                        return;
                                    } else {
                                        this.retorno.id_caixa = this.value2.id_caixa
                                    }
                                } 
                                await this.saveRetorno()
                            }
                        })
                        .catch(err => {
                            showError(err)
                            this.statusImpressao = 'falha'
                            this.mensagemImpressao = 'Falha no envio do arquivo.'
                            this.situacaoRetorno = 'falha' 
                        })
                    }
    
                })
                .catch(err => {
                    showError(err)
                }) 
            }
            
                          
        },

        async enviarRetorno() {
            if (this.contaRetorno < 1 || !this.value2.id_caixa) {
                showError('Selecione a conta bancária e o caixa para liquidação.');
                return;
            }

            try {
                // Configuração inicial
                this.overlayRetorno = true;
                this.mensagemImpressao = 'Buscando parâmetros...';                

                // Obter dados da conta bancária
                const contaData = await axios.get(`${baseApiUrl}/contasbancariasretorno/${this.contaRetorno}`);
                
                if (!contaData.data) {
                    showError('Nenhuma configuração de conta retornada!');
                    this.overlayRetorno = false;
                    return;
                }

                const dados = contaData.data;
                this.mensagemImpressao = 'Formando arquivo...';

                // Obter base64 do arquivo
                const arqBase64 = await this.getBase64(this.arqRetorno);
                this.arqBase64 = arqBase64;
                this.mensagemImpressao = 'Enviando para validação...';

                // Definir cabeçalhos globais
                axios.defaults.headers.common['token-sh'] = dados.token;
                axios.defaults.headers.common['cnpj-cedente'] = dados.cnpjCedente;
                axios.defaults.headers.common['cnpj-sh'] = dados.cnpjSh;

                // Enviar arquivo para validação
                const validationResponse = await axios.post(dados.url, { arquivo: this.arqBase64 });

                if (validationResponse.data._status !== 'sucesso') {
                    showError('Falha ao enviar arquivo de retorno');
                    this.overlayRetorno = false;
                    return;
                }

                this.mensagemImpressao = 'Validando arquivo de retorno...';
                this.situacaoRetorno = validationResponse.data._dados.situacao;
                let idsBol = []
                let idsBolString = ''
                if (validationResponse.data._dados.titulos) {
                    for (const t of validationResponse.data._dados.titulos) {
                        idsBol.push(t.idIntegracao)
                    }

                    idsBolString = JSON.stringify(idsBol)
                }

                // Configurar retorno
                this.retorno = {
                    id_caixa: this.value2.id_caixa,
                    id_conta_bancaria: this.contaRetorno,
                    nome_arquivo: this.arqRetorno.name,
                    protocolo: validationResponse.data._dados.protocolo,
                    status: 'E',
                    idsBoletos: idsBolString
                };

                // Aguardar a validação do status
                await this.checkStatus(dados.url, validationResponse.data._dados.protocolo);
                await this.saveRetorno();
            } catch (err) {
                showError(err);
            } finally {
                this.overlayRetorno = false;
            }
        },

        async checkStatus(url, protocolo) {
            let tentativas = 0;
            const maxTentativas = 3;
            const delay = 5000;

            while (tentativas < maxTentativas) {
                tentativas++;

                await axios({
                    url: `${url}/${protocolo}`,
                    method: 'GET'
                })
                .then(async (r) => {
                    if (r.data._status === 'sucesso') {
                        if (r.data._dados.situacao === 'PROCESSADO') {
                            showSuccess('Arquivo enviado e validado com sucesso.')
                            this.retorno.status = 'P'
                            let idsBol = []
                            let idsBolString = ''
                            if (r.data._dados.titulos) {
                                for (const t of r.data._dados.titulos) {
                                    idsBol.push(t.idIntegracao)
                                }

                                idsBolString = JSON.stringify(idsBol)
                            }

                            this.retorno.idsBoletos = idsBolString

                            tentativas = 3
                        } else {
                            if( r.data._dados.situacao === 'FALHA') {
                                this.retorno.status = 'F'
                                this.retorno.motivo = r.data._dados.mensagem
                            }  else {
                                this.retorno.status = 'E'  
                                this.retorno.motivo = 'Enviado para validação'
                            }                          
                        }                         

                        // alert(JSON.stringify(r.data))
                    }
                })
                .catch(err => {
                    showError(err.response.data._mensagem || err.response || 'Erro desconhecido da validação do arquivo.')
                    this.retorno.status = 'F'  
                    this.retorno.motivo = err.response.data._mensagem || err.response || 'Erro desconhecido da validação do arquivo.'
                })

                if (tentativas < maxTentativas) {
                    this.retorno.status = 'E';
                    await new Promise(resolve => setTimeout(resolve, delay)); // Atraso entre tentativas
                }
            }
        },

        loadRetornos() {  
            if (this.contaRetorno < 1) {

                showError('Selecione a conta bancária')

            } else {
                const url = `${baseApiUrl}/retornos/${this.contaRetorno}` 
    
                axios.get(url).then(res => {
                    this.retornos = res.data
                }) 
                .catch(err => {
                    showError(err)
                })
            }
        },

        async saveRetorno() {            
            const method = this.retorno.id_boleto_retorno ? 'put' : 'post'
            const idretorno = this.retorno.id_boleto_retorno ? `/${this.retorno.id_boleto_retorno}` : ''           

            axios[method](`${baseApiUrl}/retornos${idretorno}`, this.retorno)
            .then(()=>{
                this.loadRetornos()
                this.retorno = {}
            })
            .catch(err => {
                showError(err)
            })            
        },  

        selectAllRowsBoletos() {
            this.boletos.forEach((item) => {
                this.$set(item, 'selected', true)
                this.boletosSelecteds = [ ...this.selectedRows ]
                this.boletosSelecionados = this.boletosSelecteds.map(b=> {return b.id_boleto_emitido})
            })
        },

        clearSelectedBoletos() {
            this.selectedRows.forEach((item) => {
                this.$delete(item, 'selected')
                this.boletosSelecteds = []
                this.boletosSelecionados = []
            })
        },

        tbodyRowClass(item) {
            /* Style the row as needed */
            if (!this.isBusy) {

                if(item.selected){
                    return ["b-table-row-selected", "table-primary", "cursor-pointer"]
                } else {
                    return ["cursor-pointer"]
                }
            }

        },

        rowClicked(item) {
            if(item.selected){      
                this.$set(item, 'selected', false)

                this.boletosSelecteds = [ ...this.selectedRows ]
                this.boletosSelecionados = this.boletosSelecteds.map(b=> {return b.id_boleto_emitido})
            }else{
                this.$set(item, 'selected', true)

                this.boletosSelecteds = [ ...this.selectedRows ]
                this.boletosSelecionados = this.boletosSelecteds.map(b=> {return b.id_boleto_emitido})
            }
        },
    
    },
    mounted() {
        this.loadContasBancarias(),
        this.loadCaixas(),
        this.loadFiliais()
    },
    watch: {        
        contaRetorno() {
            this.loadRetornos()
        },
        'paramBoletos.conta': function() {
            this.loadConvenios()
        },
        search(newSearch) {
            this.overlaySearch = true

            // Se já houver um timeout, cancelamos o anterior
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout)
            }

            // Definimos o novo timeout para 1 segundo
            this.debounceTimeout = setTimeout(() => {
                this.loadCaixas(newSearch); // Executa a busca
            }, 1500);
        }

    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .option-boletos {
        min-height:  350px;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;        
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
        max-width: 250px
    };

    .containerRoll {
        height: calc(100vh - 100px); /* Ajuste a altura de acordo com a altura do card fixo */
        overflow-y: auto;
        scrollbar-width: none; /* Para navegadores Firefox */
        -ms-overflow-style: none; /* Para Internet Explorer e Edge */
        padding-bottom: 60px;
    }

    .containerRoll::-webkit-scrollbar {
        display: none; /* Para navegadores WebKit (Chrome, Safari) */
    }

    .sticky-card-bol {
        position: sticky;
        top: 0; 
        background-color: #f9f9f9;
        z-index: 5; /* Para garantir que fique acima de outros elementos */
    }

    .div-topo-b {
        background: linear-gradient(to bottom, white, transparent);
        height: 50px;
        margin-top: -16px;

        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-image: linear-gradient(to bottom, rgb(223, 222, 222), transparent) 1;
    }

    .acoesTopo-bol {
        margin-top: -80px !important;
        margin-bottom: 80px !important;
    }

    .tab-topo-bol {
        padding: 0 20px 0 20px;
    }

    .spinner-overlay {
        font-size: 2rem;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .b-table td {
    word-wrap: break-word;  /* Garante que a palavra longa será quebrada */
    word-break: break-word; /* Força a quebra da palavra sem espaços */
    white-space: normal;    /* Permite que o texto quebre dentro da célula */
}
    



</style>